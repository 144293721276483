import React, { Suspense } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import LogoutGuard from "@components/logout-guard";
import LoginGuard from "@components/login-guard";
import AdminGuard from "@components/admin-guard";
import EditorLayout from "./layouts/EditorLayout";
import HomeLayout from "./layouts/home-layout";
import LoadIndicator from "@components/load-indicator";
import { lazyRetry } from "@utils/lazy-try.util";
import ErrorPage from "@components/error-page";

const Login = React.lazy(() =>
  lazyRetry(() => import("./pages/account/login"))
);
const ResetPasswordPage = React.lazy(() =>
  lazyRetry(() => import("./pages/account/reset-password"))
);

const LayoutSection = React.lazy(() =>
  lazyRetry(() => import("./layouts/layout"))
);

const UserPage = React.lazy(() =>
  lazyRetry(() => import("./pages/admin/user-list"))
);
const ProteinEditorPage = React.lazy(() =>
  lazyRetry(() => import("./pages/protein-editor"))
);
const ProjectListPage = React.lazy(() =>
  lazyRetry(() => import("./pages/project-list"))
);
const JobListPage = React.lazy(() =>
  lazyRetry(() => import("./pages/job-list"))
);
const RegisterPage = React.lazy(() =>
  lazyRetry(() => import("./pages/account/register"))
);
const FileListPage = React.lazy(() =>
  lazyRetry(() => import("./pages/file-list"))
);
const PricingPage = React.lazy(() =>
  lazyRetry(() => import("./pages/admin/pricing"))
);
const NotificationListPage = React.lazy(() =>
  lazyRetry(() => import("./pages/notification-list"))
);
const AccountPage = React.lazy(() =>
  lazyRetry(() => import("./pages/account"))
);

const PlansPage = React.lazy(() =>
  lazyRetry(() => import("./pages/admin/plans"))
);

const BillingPage = React.lazy(() =>
  lazyRetry(() => import("./pages/account/components/billing"))
);

const HomePage = React.lazy(() => lazyRetry(() => import("./pages/home")));
const NotificationUnsubscribePage = React.lazy(() =>
  lazyRetry(() => import("./pages/notification-list/notification-unsubscribe"))
);

const ApplyTrial = React.lazy(() =>
  lazyRetry(() => import("./pages/account/apply-trial"))
);

const RegistrationListPage = React.lazy(() =>
  lazyRetry(() => import("./pages/admin/registration-list"))
);
const PlanFormPage = React.lazy(() =>
  lazyRetry(() => import("./pages/admin/plan-form"))
);

const OrganizationListPage = React.lazy(() =>
  lazyRetry(() => import("./pages/admin/organization-list"))
);

const BillingHistoryPage = React.lazy(() =>
  lazyRetry(() => import("./pages/admin/billing-history"))
);

const OrganizationDetailPage = React.lazy(() =>
  lazyRetry(() => import("./pages/admin/organization-detail"))
);
const IndexPage = React.lazy(() => lazyRetry(() => import("./pages/index")));

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <LogoutGuard>
        <Suspense>
          <Login />
        </Suspense>
      </LogoutGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/reset-password",
    element: (
      <LogoutGuard>
        <Suspense>
          <ResetPasswordPage />
        </Suspense>
      </LogoutGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/register",
    element: (
      <LogoutGuard>
        <Suspense>
          <RegisterPage />
        </Suspense>
      </LogoutGuard>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/editor",
    element: (
      <LoginGuard>
        {/* <Suspense> */}
        <EditorLayout />
        {/* </Suspense> */}
      </LoginGuard>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: ":id",
        element: (
          <Suspense fallback={<LoadIndicator />}>
            <ProteinEditorPage />
          </Suspense>
        ),
      },
    ],
  },

  {
    path: "/dashboard",
    element: (
      <LoginGuard>
        <Suspense>
          <LayoutSection />
        </Suspense>
      </LoginGuard>
    ),
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Navigate to="/dashboard/projects" replace /> },
      {
        path: "projects",
        element: (
          <Suspense>
            <ProjectListPage />
          </Suspense>
        ),
      },
      {
        path: "jobs",
        element: (
          <Suspense>
            <JobListPage />
          </Suspense>
        ),
      },
      {
        path: "files",
        element: (
          <Suspense>
            <FileListPage />
          </Suspense>
        ),
      },
      {
        path: "notifications",
        element: (
          <Suspense>
            <NotificationListPage />
          </Suspense>
        ),
      },
      {
        path: "profile",
        element: (
          <Suspense>
            <AccountPage />
          </Suspense>
        ),
      },
      {
        path: "billing-detail",
        element: (
          <Suspense>
            <BillingPage />
          </Suspense>
        ),
      },
      {
        path: "users",
        element: (
          <AdminGuard>
            <Suspense>
              <UserPage />
            </Suspense>
          </AdminGuard>
        ),
      },
      {
        path: "organizations",
        children: [
          {
            path: "",
            element: (
              <AdminGuard>
                <Suspense>
                  <OrganizationListPage />
                </Suspense>
              </AdminGuard>
            ),
          },
          {
            path: "create",
            element: (
              <AdminGuard>
                <Suspense>
                  <PlanFormPage />
                </Suspense>
              </AdminGuard>
            ),
          },
          {
            path: "billing-history/:id",
            element: (
              <AdminGuard>
                <Suspense>
                  <BillingHistoryPage />
                </Suspense>
              </AdminGuard>
            ),
          },
        ],
      },
      {
        path: "organization",
        children: [
          {
            path: "",
            element: (
              <AdminGuard>
                <Suspense>
                  <OrganizationDetailPage />
                </Suspense>
              </AdminGuard>
            ),
          },
        ],
      },
      {
        path: "plans",
        element: (
          <Suspense>
            <PlansPage />
          </Suspense>
        ),
      },

      {
        path: "registration",
        element: (
          <AdminGuard>
            <Suspense>
              <RegistrationListPage />
            </Suspense>
          </AdminGuard>
        ),
      },
      {
        path: "pricing",
        element: (
          <Suspense>
            <PricingPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/index",
    element: <HomeLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: (
          <Suspense>
            <IndexPage />
          </Suspense>
        ),
      },
      {
        path: "trial",
        element: (
          <Suspense>
            <ApplyTrial />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/home",
    element: <HomeLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: (
          <Suspense>
            <HomePage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/notification-unsubscribe",
    element: (
      <Suspense>
        <NotificationUnsubscribePage />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <Navigate to="/index" replace />,
  },
]);

export default router;
