import http, { request } from "@config/http";
import { io } from "socket.io-client";
import { Page, Pagination } from "@services/common/model/page.model";
import {
  QueryParamsFileModel,
  QueryParamsJobModel,
  GeneralPayloadModel,
  AntibodyPayloadModel,
  CharacterDevelopModel,
  AntibodyAntigenModel,
  FileUploadModel,
  DeNovoAntibodyDesignModel,
  DeNovoJobTemplateModel,
  AffinityPredictionModel,
  AntibodyCommonLightChainRetrievalModel,
  AlphafoldFileModel,
  HumanizationPayloadModel,
  HumannessPredPayloadModel,
  ProteinDesignPayloadModel,
  AffinityOptPayloadModel,
  CDRModel,
  CDRResponseModel,
  CheckResidueModel,
  AntibodyOptimizationJobModel,
  UpdateAntibodyOptimizationJobModel,
  BatchFileModel,
  JobCheckParamsModel,
  FileJobDataModel,
  JobCheckResultModel,
  FileCheckParamsModel,
  FileCheckDataModel,
  AntibodyOptimizationUpdateParamsModel,
} from "./model/editor.model";

import { FileModel } from "@services/file/model/file.model";
import { JobModel } from "@services/job/model/job.model";
import { JobTypeEnum } from "@services/job/enum/job.enum";
import { API_HOST } from "@config/global.config";
import { JobResponseModel } from "@services/editor/model/job-result.model";

export const getFileByProjectApi = async (
  params: QueryParamsFileModel,
  signal?: AbortSignal
) => {
  const { data } = await http.get<null, Page<Pagination<FileModel>>>(
    `file/all/${params.pid}`,
    {
      params,
      signal,
    }
  );

  return data;
};

export const getJobByProjectApi = async (params: QueryParamsJobModel) => {
  const { data } = await http.get<null, Page<Pagination<JobModel>>>(
    `job/all/${params.pid}`,
    {
      params,
    }
  );

  return data;
};

export const downloadPdbFileApi = async (id: string) => {
  try {
    const fileName = id.toUpperCase();
    const response = await fetch(
      `https://files.rcsb.org/download/${fileName}.cif`
    );
    const blob = await response.blob();
    const file = new File([blob], `${fileName}.cif`);

    return file;
  } catch (e) {
    throw new Error("File not found");
  }
};

export const downloadAlphafoldApi = async (id: string) => {
  try {
    const [source] = await request.get<null, AlphafoldFileModel[]>(
      `https://www.alphafold.ebi.ac.uk/api/prediction/${id}`,
      {}
    );

    const response = await fetch(`${source.cifUrl}`);
    const blob = await response.blob();
    const file = new File([blob], `${source.entryId}.cif`);

    return file;
  } catch (e) {
    throw new Error("File not found");
  }
};

export const uploadFileApi = async (pid: string, formData: FormData) => {
  return http.post<null, Page<FileUploadModel>>(
    `file/upload/${pid}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Encoding": "gzip",
      },
    }
  );
};

export const createGeneralJobApi = async (
  pid: string,
  params: GeneralPayloadModel
) => {
  return http.post<null, Page<JobResponseModel>>(`job/ProStructPred/${pid}`, {
    ...params,
  });
};

export const createAntibodyJobApi = async (
  pid: string,
  params: AntibodyPayloadModel
) => {
  return http.post<null, Page<JobResponseModel>>(`job/AbStructPred/${pid}`, {
    ...params,
  });
};

export const createCharacterDevelopJobApi = async (
  pid: string,
  params: CharacterDevelopModel
) => {
  return http.post<null, Page<JobResponseModel>>(`job/DevPred/${pid}`, {
    ...params,
  });
};

export const createAntibodyAntigenJobApi = async (
  pid: string,
  params: AntibodyAntigenModel
) => {
  return http.post<null, Page<JobResponseModel>>(`job/dock/${pid}`, {
    ...params,
  });
};

export const getFileApi = async (url: string) => {
  const response = await fetch(url);
  return response.text();
};

export const createSocketInstanceApi = (token: string) => {
  const socket = io(`${API_HOST}/api/socketio`, {
    auth: {
      token,
    },
    transports: ["websocket"],
  });
  return socket;
};

export const createDeNovoAntibodyDesignJobApi = async (
  pid: string,
  params: DeNovoAntibodyDesignModel
) => {
  return http.post<null, Page<JobResponseModel>>(`job/DNAbDesign/${pid}`, {
    ...params,
  });
};

export const getJobTemplateApi = async (job_type: JobTypeEnum) => {
  const { data } = await http.get<null, Page<DeNovoJobTemplateModel[]>>(
    `file/get_templates/${job_type}`
  );

  return data;
};

export const createAffinityPredictionJobApi = async (
  pid: string,
  params: AffinityPredictionModel
) => {
  return http.post<null, Page<JobResponseModel>>(`job/AbAffPred/${pid}`, {
    ...params,
  });
};

export const createCommonLightChainRetrievalJobApi = async (
  pid: string,
  params: AntibodyCommonLightChainRetrievalModel
) => {
  return http.post<null, Page<JobResponseModel>>(
    `job/AbLightchainRetrieval/${pid}`,
    {
      ...params,
    }
  );
};

export const createHumanizationJobApi = async (
  pid: string,
  params: HumanizationPayloadModel
) => {
  return http.post<null, Page<JobResponseModel>>(`job/Humanization/${pid}`, {
    ...params,
  });
};

export const createHumannessPredJobApi = async (
  pid: string,
  params: HumannessPredPayloadModel
) => {
  return http.post<null, Page<JobResponseModel>>(`job/HumannessPred/${pid}`, {
    ...params,
  });
};

export const createProteinDesignJobApi = async (
  pid: string,
  params: ProteinDesignPayloadModel
) => {
  return http.post<null, Page<JobResponseModel>>(`job/ProMPNN/${pid}`, {
    ...params,
  });
};

export const createAffinityOptJobApi = async (
  pid: string,
  params: AffinityOptPayloadModel
) => {
  return http.post<null, Page<JobResponseModel>>(`job/AffOptim/${pid}`, {
    ...params,
  });
};

export const getCDRApi = async (params: CDRModel) => {
  const { data } = await request.get<null, Page<CDRResponseModel>>("util/cdr", {
    params,
  });
  return data?.is_cdr;
};

export const checkPdbApi = (params: CheckResidueModel) => {
  return request.get<null, Page<null>>("util/check_pdb", {
    params,
  });
};

export const createAntibodyOptimizationJobApi = async (
  pid: string,
  params: AntibodyOptimizationJobModel
) => {
  return http.post<null, Page<JobResponseModel>>(
    `job/optimization/antibody/${pid}`,
    {
      ...params,
    }
  );
};

export const updateAntibodyOptimizationJobApi = async (
  jid: string,
  params: UpdateAntibodyOptimizationJobModel
) => {
  return http.post<null, Page<null>>(
    `job/optimization/antibody/sequence_update/${jid}`,
    {
      ...params,
    }
  );
};

export const updateAntibodyOptimizationParamsApi = async (
  jid: string,
  params: AntibodyOptimizationUpdateParamsModel
) => {
  return http.post<null, Page<null>>(
    `job/optimization/antibody/update_params/${jid}`,
    {
      ...params,
    }
  );
};

export const updateAntibodyStructureApi = async (
  jid: string,
  relax: boolean,
  seq_id: string
) => {
  return http.post<null, Page<null>>(
    `job/optimization/antibody/refresh_structure/${jid}`,
    {
      relax,
      seq_id,
    }
  );
};

export const getFileJobListApi = async (pid: string) => {
  const { data } = await http.get<null, Page<FileJobDataModel>>(
    `project/${pid}/file_and_jobs`,
    {
      params: {
        pid,
      },
    }
  );

  return data;
};

export const getFilesByJidApi = async (
  jobIds: string[],
  file_types?: string[]
) => {
  const { data } = await http.post<null, Page<BatchFileModel>>(
    `job/batch_job_files`,
    {
      job_ids: jobIds,
      file_types,
    }
  );

  return data;
};

export const checkJobUpdateApi = async (
  pid: string,
  params: JobCheckParamsModel,
  signal?: AbortSignal
) => {
  const res = await http.get<null, Page<JobCheckResultModel>>(
    `project/${pid}/file_and_jobs/check_new`,
    {
      params,
      signal,
    }
  );

  if (!res) {
    return null;
  }

  return res.data;
};

export const checkFilesUpdateApi = async (
  params: FileCheckParamsModel,
  signal?: AbortSignal
) => {
  const { data } = await http.post<null, Page<FileCheckDataModel>>(
    `job/job_files/batch_check_new`,
    params,
    {
      signal,
    }
  );

  return data;
};
