import {
  HeaderLanModel,
  HomePageModel,
  HomeFooterModel,
  TrialPageModel,
  IndexPageModel,
} from "@services/home/model/home.model";
import {
  CATEGORY_VALUES_OPTIONS,
  DEVELOPMENT_PHASE_OPTIONS,
  PRIORITY_OPTIONS,
} from "@services/account/config/registration.config";

import { ReactComponent as ProteinIcon } from "@assets/index-image/protein-icon.svg";
import { ReactComponent as StructureIcon } from "@assets/index-image/structure-icon.svg";
import { ReactComponent as AffinityIcon } from "@assets/index-image/affinity-icon.svg";
import { ReactComponent as DesignIcon } from "@assets/index-image/design-icon.svg";

import professionalBgImg from "@assets/index-image/professional-bg.png";
import intelligentBgImg from "@assets/index-image/intelligent-bg.png";
import efficientBgImg from "@assets/index-image/efficient-bg.png";
import intuitiveBgImg from "@assets/index-image/intuitive-bg.png";
import antibodyOptImg from "@assets/index-image/antibody-optimization.gif";
import structureModelingImg from "@assets/index-image/structure-modeling.gif";
import affinityMaturationImg from "@assets/index-image/affinity-maturation.png";
import proteinDesignImg from "@assets/index-image/protein-design.gif";

import posterImgEn from "@assets/index-image/video-cover-image-en.png";
import posterImgCn from "@assets/index-image/video-cover-image-cn.png";

import titleCn from "@assets/home-image/title-cn.png";
import titleEn from "@assets/home-image/title-en.png";

export const pageConfig: Record<string, HomePageModel> = {
  en: {
    firstPage: {
      titleImg: titleEn,
      btnText: "Apply for a Trial",
    },
    secondPage: {
      title: "About",
      texts: [
        "Meet GeoBiologics, an AI-powered one-stop platform for antibody design.",
        "Catering to drug designers at pharmaceutical companies and research institutions, GeoBiologics harnesses the power of our latest advancements in <span class='keyword'>generative AI</span>, <span class='keyword'>geometric deep learning</span>, and <span class='keyword'>foundation models</span> and presents them in an intuitive visual format for streamlined antibody design.",
      ],
    },
    thirdPage: {
      title: "Why GeoBiologics?",
      leftTop: {
        title: "Professional",
        text: "Proudly developed by BioGeometry's scientists, GeoBiologics dives deep into antibody therapeutics development and provides a comprehensive solution.",
      },
      leftbBttom: {
        title: "Intelligent",
        text: "GeoBiologics' intelligent predictions are powered by next-generation AI algorithms trained on billions of protein sequences and &gt;&thinsp;<span style='position: relative;'>10<sup style='position:absolute;top:-11px;'>5</sup></span>&nbsp;&nbsp; protein structures.",
      },
      rightTop: {
        title: "Efficient",
        text: "No need to buy and maintain expensive GPUs. Our powerful cloud computing infra allows you to design antibodies faster than ever before.",
      },
      rightBottom: {
        title: "Intuitive",
        text: "Say no to the painful setup and learning process! Our out-of-the-box SaaS platform has an intuitiv interface designed to help you achieve your goal trouble-free.",
      },
    },
    fourthPage: {
      title: "Versatile platform, infinite possibilities",
      tabs: [
        {
          title: "Design",
          texts: [
            {
              title: "Protein / Antibody Sequence Design",
              text: "Generate a sequence that is compatible with a given protein/antibody structure.",
            },
            {
              title: "Targeted CDR Library Design",
              text: "Design a CDR library de novo targeting a given epitope.",
            },
          ],
        },
        {
          title: "Structure Modeling",
          texts: [
            {
              title: "Protein Structure Prediction",
              text: "Predict the 3D structure of a protein from its amino acid sequence.",
            },
            {
              title: "Antibody Structure Prediction",
              text: "Predict the structure of antibody variable domains (F<sub>V</sub>, including VH and VL).",
            },
            {
              title: "Antibody-Antigen Docking",
              text: "Predict the structure of an antibody-antigen complex given separate antibody and antigen structures.",
            },
          ],
        },
        {
          title: "Characterization",
          texts: [
            {
              title: "Humanness Prediction",
              text: "Predict the humanness of antibody sequences.",
            },
            {
              title: "Affinity Prediction",
              text: "Predict the binding affinity (K<sub>D</sub>) of antibody-antigen complex given its structure.",
            },
            {
              title: "Developability Prediction",
              text: "Predict various developability properties, e.g. solubility, stability, expression, multiplicity, immunogenicity, given antibody sequences.",
            },
          ],
        },
        {
          title: "Optimization",
          texts: [
            {
              title: "Antibody Humanization",
              text: "Change the antibody sequence to make it closer to a human antibody, thus reducing its immunogenicity in humans.",
            },
            {
              title: "Affinity Optimization",
              text: "Increase the antibody's affinity towards a target antigen, given the wild-type antibody-antigen complex.",
            },
          ],
        },
      ],
    },
    fifthsPage: {
      title: "Pioneering the Future of Therapeutics",
      subTitle:
        "We are leading the new paradigm of generative antibody design, bringing AI to the forefront of preclinical antibody therapeutics development. We offer unique, advantageous solutions throughout all stages of the process – from antibody generation to screening and optimization.",
      tags: [
        "Generative AI",
        "Geometric Deep Learning",
        "Foundation Model",
        "High Performance Computing",
      ],
      panels: [
        {
          label: "Target to hits",
          texts: [
            "Better developability",
            "expression + stability + solubility",
            "General library / Target-specific design",
          ],
        },
        {
          label: "Hits to leads",
          texts: [
            "comprehensive assessment",
            "affinity + developability + humanness",
            "Epitope diversity",
          ],
        },
        {
          label: "Lead optimization",
          texts: [
            "Multi-objective",
            "affinity + developability + humanness",
            "High efficiency & success rate",
          ],
        },
      ],
    },
  },
  cn: {
    firstPage: {
      titleImg: titleCn,
      btnText: "申请试用",
    },
    secondPage: {
      title: "简介",
      texts: [
        "GeoBiologics 是专为药物研发科学家打造的一站式 AI 抗体设计平台，融汇百奥几何在<span class='keyword'>生成式人工智能、几何深度学习、预训练大模型</span>的最新成果，辅以直观易用的图形界面呈现，为您的抗体开发项目注入新动能。",
        "无论是抗体药物的从头设计、结构建模、虚拟筛选还是性质优化，GeoBiologics 都能为您提供专业、智能、高效、易用的 AI 解决方案。",
      ],
    },
    thirdPage: {
      title: "平台优势",
      leftTop: {
        title: "更懂您需求的平台",
        text: "平台由深耕 AI 制药多年的百奥几何算法团队研发，深挖抗体药物研发需求，提供从靶点到临床前药物的全流程解决方案",
      },
      leftbBttom: {
        title: "精准预测，由 AI + 大数据驱动",
        text: "平台由十亿级蛋白质序列数据、数十万蛋白质结构数据和百奥几何自研的新一代 AI 算法强力驱动，预测更加精准",
      },
      rightTop: {
        title: "高效推理，智在云端",
        text: "无需购买和维护昂贵的 GPU，使用百奥几何成熟的云计算基础设施进行抗体设计，效率超出想象",
      },
      rightBottom: {
        title: "可用直观，让您专注创新",
        text: "在线即可用的 SaaS 平台，辅以美观易用的图形界面，让您告别冗长的软件安装学习过程，轻松完成任务",
      },
    },
    fourthPage: {
      title: "多样模型，点亮无限可能",
      tabs: [
        {
          title: "抗体设计",
          texts: [
            {
              title: "蛋白/抗体序列设计",
              text: "给定蛋白/抗体的结构，生成相应序列",
            },
            {
              title: "抗原特异性 CDR 库的从头设计",
              text: "给定抗体框架区域和抗原表位，从头设计 HCDR3 库",
            },
          ],
        },
        {
          title: "结构建模",
          texts: [
            {
              title: "一般蛋白的结构预测",
              text: "根据蛋白序列，预测其结构。如您需要，搜索多序列比对（MSA）和结构模板作为额外输入。",
            },
            {
              title: "抗体结构预测",
              text: "预测抗体可变域的结构。",
            },
            {
              title: "抗原抗体对接",
              text: "给定抗体序列和抗原结构，预测抗原抗体复合物的结构。考虑到抗原表位的不确定性，可以返回前 <em><i>K</></em> 个预测结果。",
            },
          ],
        },
        {
          title: "抗体性质预测",
          texts: [
            {
              title: "人源性预测",
              text: "基于序列预测抗体的人源性，即抗体序列与人源序列的相似性。",
            },
            {
              title: "亲和力预测",
              text: "基于抗原抗体复合物的结构预测亲和力（K<sub>D</sub>）",
            },
            {
              title: "成药性预测",
              text: "基于序列预测抗体的成药性，包括可溶性、稳定性、表达量、多特异性、免疫原性等。",
            },
          ],
        },
        {
          title: "抗体性质优化",
          texts: [
            {
              title: "人源化",
              text: "改变抗体序列，使其更接近人源抗体，以降低其免疫原性。",
            },
            {
              title: "亲和力优化",
              text: "使用饱和突变 + AI 排序的算法优化抗原抗体复合物的结构，提高其亲和力。",
            },
          ],
        },
      ],
    },
    fifthsPage: {
      title: "颠覆创新，领跑智药新范式",
      subTitle:
        "GeoBiologics 开启了生成式抗体设计的新范式，让 AI 深度参与和赋能临床前抗体药物研发， 在抗体生成、筛选和优化等各流程均能产生具有独特优势的解决方案。",
      tags: ["生成式 AI", "几何深度学习", "预训练大模型", "高性能计算"],
      panels: [
        {
          label: "苗头抗体生成",
          texts: [
            "更好的成药性 通用库",
            "(表达量+稳定性+可溶性)",
            "靶点特异库",
          ],
        },
        {
          label: "先导抗体筛选",
          texts: [
            "系统评估抗体",
            "(亲和力+成药性+人源性)",
            "表位多样化，提高成功率",
          ],
        },
        {
          label: "先导抗体优化",
          texts: ["多目标优化", "(亲和力+成药性+人源性)", "高效，提高成功率"],
        },
      ],
    },
  },
};

export const headerConfig: Record<string, HeaderLanModel> = {
  en: {
    lan: "中文",
    btnText: "Login",
    applyText: "Apply for a Trial",
  },
  cn: {
    lan: "English",
    btnText: "登录",
    applyText: "申请试用",
  },
};

export const footerConfig: Record<string, HomeFooterModel> = {
  en: {
    quickLink: {
      title: "Quick link",
      switchText: "中文",
      links: [
        {
          name: "BioGeometry homepage",
          url: "https://www.biogeom.com",
          external: true,
        },
        {
          name: "Apply for a trial",
          url: "/index/trial",
          external: false,
        },
        {
          name: "Login",
          url: "/login",
          external: false,
        },
      ],
    },
    locations: [
      {
        name: "Beijing Headquarters",
        value: "1505 Zhongguancun SOHO, Haidian, Beijing, China, 100083",
      },
      {
        name: "Beijing Wet Lab",
        value:
          "L320, Bldg 2, Yard 9, Yiliaoyuan Rd, Changping, Beijing, China, 102206",
      },
      {
        name: "Shanghai R&D Center",
        value: "1st Floor, Bldg D, 158 Gu'en Road, Shanghai, China, 201314",
      },
    ],
    contacts: [
      {
        name: "Business Collaboration",
        value: "bd@biogeom.com",
      },
      {
        name: "Media Contact",
        value: "pr@biogeom.com",
      },
      {
        name: "General Information",
        value: "info@biogeom.com",
      },
      {
        name: "Career Opportunities",
        value: "recruitment@biogeom.com",
      },
    ],
  },
  cn: {
    quickLink: {
      title: "快速链接",
      switchText: "English",
      links: [
        {
          name: "BioGeometry 官网",
          url: "https://www.biogeom.com/zh/",
          external: true,
        },
        {
          name: "申请试用",
          url: "/index/trial",
          external: false,
        },
        {
          name: "登录",
          url: "/login",
          external: false,
        },
      ],
    },
    locations: [
      {
        name: "北京办公室",
        value: "北京市海淀区 海淀北二街8号 中关村SOHO 1505, 100083",
      },
      {
        name: "北京实验室",
        value: "北京市昌平区 医疗园路 新生巢创新中心 L320, 102206",
      },
      {
        name: "上海研发中心",
        value: "上海市浦东新区 古恩路158号 悦丰生命科学园 D栋1楼, 201314",
      },
    ],
    contacts: [
      {
        name: "商务合作",
        value: "bd@biogeom.com",
      },
      {
        name: "媒体联络",
        value: "pr@biogeom.com",
      },
      {
        name: "信息咨询",
        value: "info@biogeom.com",
      },
      {
        name: "工作机会",
        value: "recruitment@biogeom.com",
      },
    ],
  },
};

export const APPLY_TRIAL_CONFIG: Record<string, TrialPageModel> = {
  en: {
    title: "GeoBiologics Trial Application",
    subtitle:
      "We'll contact you within three working days of receiving your application.",
    name: {
      text: "Your name",
      placeholder: "Enter your name here",
    },
    company: {
      text: "Institution",
      placeholder: "Enter the name of a corporation/university/college/etc.",
    },
    job_position: {
      text: "Your position",
      placeholder: "E.g., antibody discovery scientist",
    },
    email: {
      text: "Working email",
      placeholder: "Enter a valid email for working institution",
    },
    phone: {
      text: "Phone number",
      placeholder: "Enter your phone number here",
    },
    mol_category_values: {
      text: "What types of molecules are you working on/interested in?",
      options: CATEGORY_VALUES_OPTIONS,
    },
    mol_category_other: {
      placeholder: "Please Enter content",
    },
    development_phase_values: {
      text: "Which drug discovery stages do you wish to use AI in?",
      options: DEVELOPMENT_PHASE_OPTIONS,
    },
    development_phase_other: {
      placeholder: "Please Enter content",
    },
    priority_values: {
      text: "Do you have a specific project at hand that could use AI service?",
      options: PRIORITY_OPTIONS,
    },
    submitText: "Submit",
    message: "This field is required",
  },
  cn: {
    title: "申请试用 GeoBiologics",
    subtitle: "我们的工作人员将会在申请提交后三个工作日内与您取得联系。",
    name: {
      text: "您的姓名",
      placeholder: "请输入",
    },
    company: {
      text: "所属机构",
      placeholder: "企业名称/学校名称",
    },
    job_position: {
      text: "您的职位",
      placeholder: "例如：抗体发现研究员",
    },
    email: {
      text: "机构邮箱",
      placeholder: "请输入您所属机构的工作邮箱",
    },
    phone: {
      text: "手机号码",
      placeholder: "请输入",
    },
    mol_category_values: {
      text: "您目前从事/感兴趣药物研发领域",
      options: [
        {
          name: "小分子药物",
          value: "small_mol_develop",
        },
        {
          name: "抗体（单抗/双抗/纳米抗体等）",
          value: "antibody",
        },
        {
          name: "XDC（抗体偶联/多肽偶联药物等）",
          value: "XDC",
        },
        {
          name: "多肽",
          value: "peptide",
        },
        {
          name: "酶/合成生物学",
          value: "enzyme_or_synthetic_biology",
        },
        {
          name: "其他",
          value: "other",
        },
      ],
    },
    mol_category_other: {
      placeholder: "请输入",
    },
    development_phase_values: {
      text: "您希望能使用 AI 帮助大分子设计和开发的哪些阶段",
      options: [
        {
          name: "分子发现/生成（AI 驱动的苗头化合物生成）",
          value: "mol_explore",
        },
        {
          name: "分子筛选（hit to lead，性质预测及筛选，表位预测）",
          value: "mol_filter",
        },
        {
          name: "先导化合物优化（结构分析，人源化，亲和力优化，成药性优化等）",
          value: "optimize",
        },
        {
          name: "其他",
          value: "other",
        },
      ],
    },
    development_phase_other: {
      placeholder: "请输入",
    },
    priority_values: {
      text: "您目前是否有使用 AI 服务的项目需求？",
      options: [
        {
          name: "手头有正在进行项目，想尽快进行 GeoBiologics 平台试用或开展项目合作",
          value: "want_try_now",
        },
        {
          name: "有兴趣了解和沟通，想讨论后选定合适的项目",
          value: "want_deeper_communication",
        },
        {
          name: "看好 AI 的前景，未来有需求时我会尝试 GeoBiologics",
          value: "may_try_later",
        },
        {
          name: "不太确定，GeoBiologics 和我目前的工作不太相关",
          value: "not_sure",
        },
      ],
    },
    submitText: "提交申请",
    message: "此选项必填",
  },
};

export const indexPageConfig: Record<string, IndexPageModel> = {
  en: {
    firstPage: {
      titles: ["Your one-stop platform for", "AI-driven antibody development"],
      text: "Catering to pioneering drug designers, GeoBiologics harnesses the power of our latest protein foundation model GeoFlow and presents them in an intuitive visual format for streamlined antibody design.",
      trialBtn: {
        name: "Apply for a Trial",
        value: "/index/trial",
      },
      loginBtn: {
        name: "Login",
        value: "/login",
      },
    },
    secondPage: {
      videoUrl:
        "https://open-res.biogeom.com/media/geobiologics/geobiologics-intro-en.mp4",
      poster: posterImgEn,
    },
    thirdPage: {
      sectionTitle: "Why GeoBiologics?",
      tabs: [
        {
          index: "01",
          title: "Professional",
          text: "GeoBiologics covers key antibody properties such as structure, affinity, thermal/chemical stability, immunogenicity, aggregation, etc., providing a comprehensive AI solution for antibody development.",
          backgroundImg: professionalBgImg,
        },
        {
          index: "02",
          title: "Intelligent",
          text: "GeoBiologics' intelligent predictions are powered by BioGeometry’s next-generation AI algorithms trained on billions of proteins with 10x improvements over traditional rational design methods.",
          backgroundImg: intelligentBgImg,
        },
        {
          index: "03",
          title: "Efficient",
          text: "GeoBiologics adds AI to your experiment design, discovering molecules with multiple properties optimized and risks mitigated, enabling you to test less but achieve more.",
          backgroundImg: efficientBgImg,
        },
        {
          index: "04",
          title: "Intuitive",
          text: "Say no to the painful setup and learning process! Our out-of-the-box SaaS platform with an intuitive user interface is designed to help you achieve your goal trouble-free.",
          backgroundImg: intuitiveBgImg,
        },
      ],
    },
    fourthPage: {
      sectionTitle: "Features",
      tabs: [
        {
          icon: ProteinIcon,
          title: "Antibody Optimization",
          text: "Optimize the expression, aggregation, immunogenicity and stability of your antibody with your AI agent",
        },
        {
          icon: StructureIcon,
          title: "Structure Modeling",
          text: "Model and analyze the structure of proteins, antibodies, TCRs, antibody-antigen complexes with state-of-the-art precision",
        },
        {
          icon: AffinityIcon,
          title: "Affinity maturation",
          text: "Enhance the affinity of your protein binder against its target with ultra-fast virtual screening",
        },
        {
          icon: DesignIcon,
          title: "Protein design",
          text: "Design novel protein sequences/structures that meet your need",
        },
      ],
      images: [
        antibodyOptImg,
        structureModelingImg,
        affinityMaturationImg,
        proteinDesignImg,
      ],
    },
  },
  cn: {
    firstPage: {
      titles: ["生成式AI", "驱动的抗体设计平台"],
      text: "专为下一代蛋白研发打造，“干湿结合+多目标优化”为您的抗体开发项目提质增效",
      trialBtn: {
        name: "申请试用",
        value: "/index/trial",
      },
      loginBtn: {
        name: "登录",
        value: "/login",
      },
    },
    secondPage: {
      videoUrl:
        "https://open-res.biogeom.com/media/geobiologics/geobiologics-intro-cn.mp4",
      poster: posterImgCn,
    },
    thirdPage: {
      sectionTitle: "平台优势",
      tabs: [
        {
          index: "01",
          title: "专业 ",
          text: "平台覆盖抗体结构预测、亲和力、热/化学稳定性、免疫原性等多个关键性质，支持从头设计和多目标优化，提供从靶点到 PCC 的全流程 AI 解决方案。",
          backgroundImg: professionalBgImg,
        },
        {
          index: "02",
          title: "智能",
          text: "平台由深耕 AI 制药多年的图灵奖团队研发，基于 GeoFlow 蛋白设计大模型，由十亿级蛋白质数据强力驱动，多个模块性能远超传统理性设计方法。",
          backgroundImg: intelligentBgImg,
        },
        {
          index: "03",
          title: "高效",
          text: "从湿实验为主到数智驱动、干湿结合，为您更快找到多个性质更优的分子、提前排除高危分子，大大减少湿实验负担，创造实实在在的价值。",
          backgroundImg: efficientBgImg,
        },
        {
          index: "04",
          title: "易用",
          text: "在线即可用的 SaaS 平台，依托百奥几何成熟的云计算基础设施，辅以美观易用的图形界面，让您告别冗长的硬件配置、软件安装过程，轻松使用 AI 服务。",
          backgroundImg: intuitiveBgImg,
        },
      ],
    },
    fourthPage: {
      sectionTitle: "功能模块",
      tabs: [
        {
          icon: ProteinIcon,
          title: "抗体综合优化",
          text: "综合提升抗体的表达量、聚集性、免疫原性、稳定性，为项目提质增效",
        },
        {
          icon: StructureIcon,
          title: "结构建模",
          text: "精准建模并分析单链蛋白、抗体、TCR、抗原-抗体复合物的结构，预测抗原表位",
        },
        {
          icon: AffinityIcon,
          title: "亲和力成熟",
          text: "快速优化您的蛋白配体的亲和力，得到活性更高的突变体",
        },
        {
          icon: DesignIcon,
          title: "蛋白设计",
          text: "设计全新的蛋白序列/结构",
        },
      ],
      images: [
        antibodyOptImg,
        structureModelingImg,
        affinityMaturationImg,
        proteinDesignImg,
      ],
    },
  },
};
