import { useState, useEffect, useRef } from "react";

interface ConfigModel {
  onActive: () => void;
  onInactive: () => void;
}

export function useUserActive({ onActive, onInactive }: ConfigModel) {
  const [active, setActive] = useState(true);

  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    let inactiveTime = 0;
    let isActive = true; // 当前活跃状态

    const maxInactiveTime = 5 * 60 * 1000; // 5 minutes

    const resetInactiveTimer = () => {
      inactiveTime = 0;
      if (!isActive) {
        console.log("user is active");
        isActive = true;
        setActive(true);
        onActive();
      }
    };

    const checkInactiveStatus = () => {
      inactiveTime += 1000; // 每秒增加1秒
      if (inactiveTime >= maxInactiveTime) {
        if (isActive) {
          isActive = false;
          setActive(false);
          onInactive();
          console.log("user is inactive");
        }
      }
      timerRef.current = setTimeout(checkInactiveStatus, 1000); // 每秒检查一次
    };

    // 事件监听
    window.addEventListener("mousemove", resetInactiveTimer);
    window.addEventListener("keypress", resetInactiveTimer);
    window.addEventListener("scroll", resetInactiveTimer);

    timerRef.current = setTimeout(checkInactiveStatus, 1000); // 每秒检查一次

    return () => {
      window.removeEventListener("mousemove", resetInactiveTimer);
      window.removeEventListener("keypress", resetInactiveTimer);
      window.removeEventListener("scroll", resetInactiveTimer);
      clearTimeout(timerRef.current);
    };
  }, []);

  return { active };
}
