import axios from "axios";
import { Toast } from "@douyinfe/semi-ui";
import { getLocalStorage } from "@utils/localstorage";
import { LoginEnum } from "@services/login/enum/login.enum";
import { TokenModel } from "@services/login/model/login.model";
import type {
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from "axios";
import type { Page } from "@services/common/model/page.model";
import { API_HOST } from "@config/global.config";
import { checkNeedAsyncDownloadApi } from "@services/file/file.api";
import { DownloadTypeEnum } from "@services/file/enum/file.enum";

export const axiosInstance = axios.create({
  baseURL: `${API_HOST}/api/`,
});

export const request = axios.create({
  baseURL: `${API_HOST}/api/`,
});

request.interceptors.request.use(
  async (
    config: InternalAxiosRequestConfig<{
      fids: string[];
      jids: string[];
      pids: string[];
    }>
  ) => {
    const { headers, data } = config;

    const tokenInfo = getLocalStorage(LoginEnum.Token) as TokenModel;
    if (tokenInfo) {
      config.headers.Authorization = tokenInfo.token;
    }

    const prefetch = headers.prefetch as string;

    if (prefetch) {
      if (prefetch === "file") {
        const {
          data: { need_async_download: needAsyncDownload },
        } = await checkNeedAsyncDownloadApi({
          download_type: DownloadTypeEnum.ByFileIds,
          file_ids: data!.fids,
        });

        if (!needAsyncDownload) {
          config.responseType = "blob";
        }
      } else if (prefetch === "job") {
        const {
          data: { need_async_download: needAsyncDownload },
        } = await checkNeedAsyncDownloadApi({
          download_type: DownloadTypeEnum.ByJobs,
          job_ids: data!.jids,
        });

        if (!needAsyncDownload) {
          config.responseType = "blob";
        }
      } else if (prefetch === "project") {
        const {
          data: { need_async_download: needAsyncDownload },
        } = await checkNeedAsyncDownloadApi({
          download_type: DownloadTypeEnum.ByProjects,
          project_ids: data!.pids,
        });

        if (!needAsyncDownload) {
          config.responseType = "blob";
        }
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  function (response: AxiosResponse<Page<null>>) {
    const { data } = response;

    // const contentType = headers["content-type"] as string;

    // if (
    //   contentType &&
    //   (contentType.includes("application/zip") ||
    //     contentType.includes("application/x-gzip") ||
    //     contentType.includes("image"))
    // ) {
    //   const { data: blobResponse } = await axios<any, AxiosResponse<Blob>>({
    //     ...response.config,
    //     responseType: "blob",
    //   });
    //   return blobResponse;
    // } else {
    //   return data as any;
    // }

    return data as any;
  },
  function (error: AxiosError<Page<null>, any>) {
    // const { response } = error;

    // if (!response) {
    //   return;
    // }
    // const { data } = response;
    // const { code, message } = data;
    // if (code !== 200) {
    //   Toast.error(message);
    // }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    const tokenInfo = getLocalStorage(LoginEnum.Token) as TokenModel;
    if (tokenInfo) {
      config.headers.Authorization = tokenInfo.token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response: AxiosResponse<Page<null>>) {
    const { data } = response;
    const { code, message } = data;

    if (code !== 200 && code !== 401) {
      Toast.error(message);
    }

    if (code === 401) {
      const currentPath = location.pathname;
      window.location.href = `/login?redirect=${encodeURIComponent(
        currentPath
      )}`;
    }
    return data as any;
  },
  function (error: AxiosError<Page<null>, any>) {
    const { response } = error;
    if (!response) {
      return;
    }
    const { data } = response;
    const { code, message } = data;
    if (code !== 200 && code !== 401) {
      Toast.error(message);
    }

    if (code === 401) {
      const currentPath = location.pathname;
      window.location.href = `/login?redirect=${encodeURIComponent(
        currentPath
      )}`;
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
