export enum SourceEnum {
  Upload = "upload",
  Output = "output",
  Pdb = "PDB",
  Afdb = "AF_DB",
}

export enum DownloadTypeEnum {
  ByProjects = "by_projects",
  ByJobs = "by_jobs",
  ByFileIds = "by_file_ids",
}
